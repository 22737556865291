import {useHubQuery} from '../../QueryProvider'

export const useGetCancellationRequestStatus = (orderId: string, orderStatus: string) => {
  return useHubQuery('cancellationRequestStatus', [orderId], {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    refetchInterval: orderStatus === 'cancelled' ? false : 30000
  })
}
